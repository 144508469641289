/*
  Icons
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
  Version: 2.0
  Created: 3.1.2019
  Last updated: 18.3.2019
*/
 .prh-icon {
  height: 2.2rem;
  width: 2.2rem;
	vertical-align: text-bottom;
	padding: 0 .1rem .1rem .1rem;
  transition: transform 0.3s ease-in-out;;
  stroke: $prh-bright-blue;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
	a:hover &,
	a:active &,
	.btn:hover &,
	.btn:active & {
		stroke: $prh-purple;
	}
}
  .prh-icon--white {
    stroke: $white;
    a:hover &,
    a:active &,
    .btn:hover &,
    .btn:active & {
      stroke: $prh-pale-blue;
    }
  }
  .prh-icon--orange {
    stroke: $prh-orange;
    a:hover &,
    a:active &,
    .btn:hover &,
    .btn:active & {
      stroke: lighten($prh-orange, 10%);
    }
  }
  .prh-icon--green {
    stroke: $prh-green;
    a:hover &,
    a:active &,
    .btn:hover &,
    .btn:active & {
      stroke: lighten($prh-green, 10%);
    }
  }
  .prh-icon--xs {
    height: 1rem;
    width: 1rem;
    padding: 0;
    padding-bottom: 0.1rem;
  }
	.prh-icon--sm {
    height: 1.4rem;
    width: 1.4rem;
	}
  .prh-icon--xl {
    width: 3.2rem;
    height: 3.2rem;
		padding: 0 .25rem .25rem .25rem;
	}
	.prh-icon--full {
		width: 100%;
		height: auto;
  }
  .prh-video-audio-icon {
    width: 50px;
    height: 50px;
  }
