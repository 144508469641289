.menu-text {
  background-color: $prh-blue;
  color: white;
  margin-left: 1rem;
  font-family: "FagoWebPro-Medium";
  font-size: 1.4rem;
    a:hover ,
    a:active {
      svg {
        stroke: white;
      }
    }
}

.active-menu-text {
  background-color: $prh-purple;
  color: white;
  margin-left: 1rem;
  font-family: "FagoWebPro-Medium";
  font-size: 1.4rem;
}

.link {
    a:hover ,
    a:active {
      svg {
        stroke: white;
      }
    }
}

.active-link {
  background-color: $prh-purple;

    a:hover ,
    a:active {
      svg {
        stroke: white;
      }
    }
}

.prh-site-header {
  .prh-site-header__container {
    .navbar {
      button {
        border: 0px;
        background-color: $prh-blue;
      }
      .navbar-toggler-icon {
        border: 0px;
        background-color: $prh-blue;
      }
    }
  }
}
