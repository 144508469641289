.tooltip-inner {
  max-width: none;
  padding: 0.75rem;
  color: #5d5d5d;
  text-align: left;
  background-color: white;
  opacity: 1;
  border-radius: 0.25rem;
  border: solid 1px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: $prh-bright-blue;
  font-family: $font-family-native;
  font-weight: 400;
}

.prh-tooltip-icon { 
  height: 30px;
  width: 30px; 
}
